<template>
  <section class="grid-view m-0 p-1">

    <b-card
      v-for="product in listviewData"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >
      <div class="item-img text-center align-items-center justify-content-center">
        <b-link>
          <b-img
            fluid
            class="card-img-top"
            :src="product.data.image.detailed_image_path"
          />
        </b-link>
      </div>

      <b-card-body>
        <div class="item-wrapper">
          <div class="pr-2">
            <!-- eslint-disable vue/no-v-html -->
            <template v-if="product.price > 0">
              <h6
                class="item-price"
                v-html="product.data.price_formatted"
              />
            </template>
            <template v-else>
              <h6
                class="item-price"
              >
                {{ $t('Preço sob consulta') }}
              </h6>
            </template>
          </div>
        </div>
        <h6 class="item-name">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="text-body text-center"
            v-html="product.name"
          />
        </h6>
        <h6
          v-if="product.code!==''"
          class="item-name"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="text-body text-center font-weight-normal"
          >
            {{ $t('Código') }}: {{ product.code }}
          </div>
        </h6>

        <h6
          class="item-name"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="text-body text-center font-weight-normal"
          >
            {{ $t('Stock') }}: {{ product.amount }}
          </div>
        </h6>

        <b-card-text class="item-description text-left pr-2 pl-2 mt-2">
          {{ product.data.short_description }}
        </b-card-text>
      </b-card-body>
    </b-card>

  </section>
</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BCardText,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
  },
  computed: {
    ...mapGetters('products_list', ['listviewData']),
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.txt-grey-link {
  color: $text-muted;
}

.grid-view {
  .ecommerce-card {
    img {
      max-height: 200px;
    }

    .item-description {
      overflow: auto;
      white-space: normal;
    }

    .item-wrapper {
      justify-content: right;
    }
  }
}
</style>
